<!--
    App menu
    Ce composant gère le rendu du menu de l'application dans son ensemble

    Slots :
    - default                   items du menu
-->

<template>
    <div class="list-group list-group-flush">
        <slot></slot>
    </div>
</template>